import { useState } from "react";
import roadmap from "../assets/Road Map.png";
import satmap from "../assets/SatMap.png";
import aeromap from "../assets/AeroMap.png";
import { useDispatch, useSelector } from "react-redux";
import { changBaseLayers } from "./mapSlice";
import { RootState } from "../store";

interface MapTypePopUp {
  selectedType: string
  onSelect: (type: string) => void
  isSideNavExpanded: boolean
}

const MapTypePopUp = ({ selectedType, onSelect, isSideNavExpanded }: MapTypePopUp) => {
  const dispatch = useDispatch();

  const handleSelect = (type: string) => {
    dispatch(changBaseLayers(type));
    onSelect(type);
  };

  return (
    <div
      className={`absolute bottom-6 ${isSideNavExpanded ? 'left-96' : 'left-36'} 
        z-[99999998] flex gap-x-2 p-2 bg-white rounded-lg shadow-md`}
      style={{ width: "fit-content" }}
    >
      {selectedType !== "maps-road" && (
        <button
          className="h-20 w-20 flex flex-col items-center justify-center cursor-pointer 
            focus:outline-none bg-white"
          onClick={() => handleSelect("maps-road")}
        >
          <img src={roadmap} alt="Roadmap" className="h-12 w-12 mb-1 rounded-md" />
          <span className="text-sm text-center">Road</span>
        </button>
      )}
      {selectedType !== "maps-satellite" && (
        <button
          className="h-20 w-20 flex flex-col items-center justify-center cursor-pointer 
            focus:outline-none bg-white"
          onClick={() => handleSelect("maps-satellite")}
        >
          <img src={satmap} alt="Satellite" className="h-12 w-12 mb-1 rounded-md" />
          <span className="text-sm text-center">Satellite</span>
        </button>
      )}
      {selectedType !== "maps-sectional" && (
        <button
          className="h-20 w-20 flex flex-col items-center justify-center cursor-pointer 
            focus:outline-none bg-white"
          onClick={() => handleSelect("maps-sectional")}
        >
          <img src={aeromap} alt="Aeronautical" className="h-12 w-12 mb-1 rounded-md" />
          <span className="text-sm text-center">Aeronautical</span>
        </button>
      )}
    </div>
  );
};

export const MapTypeButton = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedType, setSelectedType] = useState("maps-road");
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const handleSelect = (type: string) => {
    setSelectedType(type);
    setShowPopup(false);
  };
  const isSideNavExpanded = useSelector((state: RootState) => state.sideNav.expanded)

  return (
    <>
      <div
        className={`absolute bottom-0 ${isSideNavExpanded ? 'left-80' : 'left-20'} 
          z-[99999998]  transform -translate-y-1/2 flex flex-col`}>
        <button
                className={`border-2 border-black rounded-md h-14 w-14 items-center flex 
                  flex-col justify-center cursor-pointer focus:outline-none`}
          onClick={() => setShowPopup(!showPopup)}
          aria-label="Toggle Map Type Popup" 
        >
          <img
            src={
              selectedType === "maps-road"
                ? roadmap
                : selectedType === "maps-satellite"
                ? satmap
                : aeromap
            }
            alt={selectedType}
            className="h-14 w-14 rounded-md"
          />
                
        </button>
        
        <span
          className={`text-sm text-center ${
              darkMode ? "text-white" : "text-black"
          }`}
          >
          {selectedType === "maps-road"
              ? "Road"
              : selectedType === "maps-satellite"
              ? "Satellite"
              : "Aeronautical"}
          </span>
      </div>
      {showPopup && (
        <div className="ml-4">
          <MapTypePopUp 
            selectedType={selectedType} 
            isSideNavExpanded={isSideNavExpanded} 
            onSelect={handleSelect} 
            />
        </div>
      )}
    </>
  );
};
