import { useEffect, useState } from 'react'
import SurfaceObsAPI from './SurfaceObsAPI'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMETARData } from './METAR/METARAPI'
import { fetchTAFData } from './TAF/TAFAPI'
import fetchAIRMETData from './AIRMET/AIRMETAPI'
import getActiveEvents from '../Components/Alerting/AlertingSlice/getActiveAlerts'
import {
  hideAlertSETTING,
  setAlertingNotification,
  showAlertSETTINGCreate,
  showEventList,
  showEventsIfAlertNotPresent,
  sideNavRefresh,
  setEventCount
} from '../Components/Alerting/AlertingSlice/AlertingSlice'
import fetchSIGMETData from './SIGMET/SIGMETAPI'
import fetchPIREPData from './PIREP/PIREPAPI'
import { RootState } from '../store'
import NetworkData from '../interfaces/networkData'
import { getAllEvents } from '../menus/SlideOverDialog/SlideOverDialogEvents'
import { EventParser } from '../utils/ALERTUtils'
import { getUniqueSensorTypes } from '../utils/SurfaceObsUtils'
import { useSlideOverDialogContext } from '../menus/SlideOverDialog/SlideOverDialogContext'
import useErrorStatus from "../hooks/UseErrorStatus";
import { deselectMenuItemById, setSelectedItems, selectedItemIds, setSelectedItemIds } from '../menus/SideNavigation/SideNavigationSlice'
import useAlertSetting from '../Components/Alerting/useAlertSetting'
const NetworkAdapter = ({ selectedSideNav, setnetworkData }) => {
  const dispatch = useDispatch()
  const { addAlertSideNav } = useAlertSetting()
  const defaultLocation = useSelector((state) => state.user.defaultLocation)
  const alertNotification = useSelector(
    (state) => state.alert.callNetworkAdapter
  )
  const selectedItems = useSelector(
    (state: RootState) => state.sideNav.selectedItems
  )
  const selectesItemsId = useSelector(
    (state: RootState) => state.sideNav.selectedItemIds
  )
  const finalEventsToDisplay = useSelector(
    (state) => state.alert.finalEventsToDisplay
  )
  const { setTotalProducts } = useSlideOverDialogContext()
  const [surfaceObsData, setSurfaceObsData] = useState<NetworkData[]>([])
  const [metarData, setMetarData] = useState<NetworkData[]>([])
  const [tafData, setTafData] = useState<NetworkData[]>([])
  const [airmetData, setAirmetData] = useState<NetworkData[]>([])
  const [alertData, setAlertData] = useState<NetworkData[]>([])
  const [sigmetData, setSigmetData] = useState<NetworkData[]>([])
  const [pirepData, setPirepData] = useState<NetworkData[]>([])
  const [eventsData, setEventsData] = useState<NetworkData[]>([])
  const [eventsbeforeAlertData, seteventsbeforeAlertData] = useState<NetworkData[]>([])

  const errorStatusHook = useErrorStatus()

  useEffect(() => {
    setSurfaceObsData([])
    setMetarData([])
    setTafData([])
    setAirmetData([])
    setAlertData([])
    setSigmetData([])
    setPirepData([])
    setnetworkData([])
  }, [defaultLocation])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setAlertData([])
    }, 60000); 
    return () => clearInterval(intervalId);
  }, []);
  // useEffect(()=>{
  //   if(selectedItems.includes("MissionCast")){
  //     dispatch(toggleMissionCast(true))
  //   }
  //   else{
  //     dispatch(toggleMissionCast(false))
  //   }
  // },
  // [selectedItems])
  useEffect(()=>{
    setnetworkData([
      ...surfaceObsData,
      ...metarData,
      ...tafData,
      ...airmetData,
      ...alertData,
      ...sigmetData,
      ...pirepData,
      ...eventsData,
      ...eventsbeforeAlertData,
    ])
  },[alertData])
  useEffect(() => {
    let surfaceData =[]
    const fetchNetworkData = async () => {

      if (surfaceObsData.length == 0 && selectedItems.includes('Surface Observations')) {
        try {
          surfaceData = await SurfaceObsAPI(defaultLocation);
          setSurfaceObsData(surfaceData);
          setTotalProducts(getUniqueSensorTypes(surfaceData));
        } catch (error) {
          errorStatusHook.addMessage(error.message, 400, "error", false);
        dispatch(deselectMenuItemById("surface-observations-menu"))
        }
      } else if (
        surfaceObsData.length != 0 &&
        !selectedItems.includes('Surface Observations')
      ) {
        setSurfaceObsData([])
      }

      if (metarData.length == 0 && selectedItems.includes('METAR')) {
        // errorStatusHook.addMessage('Fetching METAR data', 200, 'warning', false)
        fetchMETARData(defaultLocation)
          .then((data) => {
            setMetarData(data)
          })
          .catch((error) => {
            errorStatusHook.addMessage(error.message, 400, 'error', false)
            dispatch(deselectMenuItemById("aviation-metar"))
          })
      } else if (metarData.length != 0 && !selectedItems.includes('METAR')) {
        setMetarData([])
      }

      if (tafData.length == 0 && selectedItems.includes('TAF')) {
        // errorStatusHook.addMessage('Fetching TAF data', 200, 'warning', false)
        fetchTAFData(defaultLocation)
          .then((data) => {
            setTafData(data)
          })
          .catch((error) => {
            errorStatusHook.addMessage(error.message, 400, 'error', false)
            dispatch(deselectMenuItemById("aviation-taf"))
            })
      } else if (tafData.length != 0 && !selectedItems.includes('TAF')) {
        setTafData([])
      }

      if (airmetData.length == 0 && selectedItems.includes('AIRMET')) {
        // errorStatusHook.addMessage('Fetching AIRMET data', 200, 'warning', false)
        fetchAIRMETData(defaultLocation)
          .then((data) => {
            setAirmetData(data)
          })
          .catch((error) => {
            errorStatusHook.addMessage(error.message, 400, 'error', false)
            dispatch(deselectMenuItemById("aviation-airmet"))
            })
      } else if (airmetData.length != 0 && !selectedItems.includes('AIRMET')) {
        setAirmetData([])
      }

      if (sigmetData.length == 0 && selectedItems.includes('SIGMET')) {
        // errorStatusHook.addMessage('Fetching SIGMET data', 200, 'warning', false)
        fetchSIGMETData(defaultLocation)
          .then((data) => {
            setSigmetData(data)
          })
          .catch((error) => {
            errorStatusHook.addMessage(error.message, 400, 'error', false)
            dispatch(deselectMenuItemById("aviation-sigmet"))
            })
      } else if (sigmetData.length != 0 && !selectedItems.includes('SIGMET')) {
        setSigmetData([])
      }

      if (pirepData.length == 0 && selectedItems.includes('PIREP')) {
        // errorStatusHook.addMessage('Fetching PIREP data', 200, 'warning', false)
        fetchPIREPData(defaultLocation)
          .then((data) => {
            setPirepData(data)
          })
          .catch((error) => {
            errorStatusHook.addMessage(error.message, 400, 'error', false)
            dispatch(deselectMenuItemById("aviation-pirep"))
            })
      } else if (pirepData.length != 0 && !selectedItems.includes('PIREP')) {
        setPirepData([])
      }

      if (
        alertData.length == 0 &&
        (selectedItems.includes('Alerts') || selectedItems.includes('Alertss'))
      ) {    
        dispatch(sideNavRefresh())
        var updatedItemIds = []
        for (const itemID of selectesItemsId) {
          if(itemID == "alerts-create" || itemID == "alerts-menu" || !itemID.startsWith("alerts") ){
            updatedItemIds.push(itemID)
          }
        }
        dispatch(setSelectedItemIds(updatedItemIds))        
        const updates = []
        const val = Object.values(finalEventsToDisplay)
        if (val?.length > 0) {
          const valuesArray = [...val]
          const parsedData = EventParser(valuesArray)
          seteventsbeforeAlertData(parsedData)
          updates.push(parsedData)
        }
        // setnetworkData((prevState) => (prevState !== null ? [...prevState, ...updates.flat()] : updates.flat()));
        var hazardType_label_map = {
          "TEMPERATURE" : "Temperature",
          "WINDS_80M" : "250FT Wind Speed",
          "WINDS_RTMA" : "Surface Wind Speed",
          "VISIBILITY" : "Visibility",
          "WINDGUSTS_RTMA" : "Surface Wind Gust",
          "CEILINGS" : "Ceiling",
          "MRMS_PRECIP": "Precipitation",
          "LIGHTNING_ALERT": "Lightning",
          "TOR_WARNING_NWS" : "NWS Alerts",
          "SVR_WARNING_NWS" : "NWS Alerts"
        }

        var hazardType_id_map = {
          "TEMPERATURE" : "alerts-temperature",
          "WINDS_80M" : "alerts-wind-speed",
          "WINDS_RTMA" : "alerts-surface-winds",
          "VISIBILITY" : "alerts-visibility",
          "WINDGUSTS_RTMA" : "alerts-wind-gust",
          "CEILINGS" : "alerts-ceiling",
          "MRMS_PRECIP": "alerts-precipitation",
          "LIGHTNING_ALERT" : "alerts-lightning",
          "TOR_WARNING_NWS" : "alerts-nws",
          "SVR_WARNING_NWS" : "alerts-nws"
        }
        const uniqueHazard = new Set()
        getActiveEvents()
          .then((eventData) => {
            const [alertData, eventCount] = eventData
            dispatch(setAlertingNotification(alertData))
            dispatch(setEventCount(eventCount))
            // for (const alert of alertData) {
            //   uniqueHazard.add(alert['properties']['hazardType'])
            // }
            // for (const hazardType of uniqueHazard) {
            //   addAlertSideNav(hazardType_label_map[hazardType])
            //   dispatch(setSelectedItemIds(hazardType_id_map[hazardType]))
            // }
            if (alertData?.length > 0) {
              setAlertData(alertData)
              updates.push(alertData)
            }
          })
          .catch((err) => {
            errorStatusHook.addMessage(
              "Alerting can't be accessed. Please check your subscription and try again.",
              400,
              'error',
              false
            )
          })
      } else if (
        (alertData.length != 0 || eventsbeforeAlertData != 0) &&
        !alertNotification &&
        !selectedItems.includes('Alerts')
      ) {
        //dispatch(hideAlertSETTING())
        //dispatch(showAlertSETTINGCreate(false))
        dispatch(sideNavRefresh())
        dispatch(showEventsIfAlertNotPresent(false));
        dispatch(showEventList(false))
        if(selectedItems.includes("Events List")){
          let tempSelectedItems = [...selectedItems]
          tempSelectedItems = tempSelectedItems.filter(item => item !== "Events List")
          dispatch(setSelectedItems(tempSelectedItems))
        }
        setAlertData([])
        seteventsbeforeAlertData([])
      }
      // To Add Other API calls for Map Markers with Table Popup Add Here
      // Keep Data type in format interface NetworkData
      if (eventsData.length == 0 && selectedItems.includes('Events List') ) {
        dispatch(showEventsIfAlertNotPresent(false))
        seteventsbeforeAlertData([])
        dispatch(showEventList(true))
        const tempeventsData = await getAllEvents()        
        const parsedData = EventParser(tempeventsData)
        if (tempeventsData?.length > 0) {
          setEventsData(parsedData)
        }
      } else if (eventsData.length != 0 && !selectedItems.includes('Events List')) {
        dispatch(showEventList(false))
        setEventsData([])
      }
      
      const l = surfaceData.length != 0 ? surfaceData : surfaceObsData
      setnetworkData([
        ...l,
        ...metarData,
        ...tafData,
        ...airmetData,
        ...alertData,
        ...sigmetData,
        ...pirepData,
        ...eventsData,
        ...eventsbeforeAlertData,
      ])
    }
    fetchNetworkData()
  }, [
    selectedItems,
    setnetworkData,
    selectedSideNav,
    surfaceObsData,
    metarData,
    tafData,
    airmetData,
    alertData,
    sigmetData,
    pirepData,
    eventsData
  ])
}
export default NetworkAdapter
